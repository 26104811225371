const studentGenderReducer = (state = [], action) => {
    switch (action.type) {
      case "STUDENT_GENDER": {
        return action.response;
      }
      default:
        return state;
    }
  };
  
  export default studentGenderReducer;
  