import './App.scss';
import React, { useEffect, useState } from 'react';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { redirect, useLocation } from 'react-router-dom';
import Error from "./common/error/Error";
import ClientHeader from './shared/ClientHeader';

function App() {
  let location = useLocation();
  const [isFullPageLayout, setIsfullPageLayout] = useState(false);
  const userPref = JSON.parse(localStorage.getItem("userPreferences"));

  useEffect(() => {
    onRouteChanged();
  }, []);

  useEffect(() => {

    if (location) {
      onRouteChanged();
    }
  }, [location]);


  const isLoggedIn = () => {
    if (sessionStorage.getItem("token")?.length > 0) {
      return true
    }
    else {
      return false;
    }
  }
  const onRouteChanged = () => {
    window.scrollTo(0, 0);
    if (!(sessionStorage.getItem("token")?.length > 0)) {
      setIsfullPageLayout(true);
      resetLayout();
      redirect("/login")
    }

    resetLayout();
  }

  const resetLayout = () => {
    if (location.pathname === '/rtl') {
      document.querySelector('body').classList.add('rtl');
    } else {
      document.querySelector('body').classList.remove('rtl');
    }
    const fullPageLayoutRoutes = ['/', '/login', '/resetpassword'];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (location.pathname?.toLowerCase() === fullPageLayoutRoutes[i]) {
        setIsfullPageLayout(true)
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        setIsfullPageLayout(false)
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

  return (
    <div className="container-scroller">
      <Error />
      {(isFullPageLayout === false && isLoggedIn() === true) ? <Navbar /> : ""}
      <div className="container-fluid page-body-wrapper">
        {(isFullPageLayout === false && isLoggedIn() === true) ? <Sidebar /> : ""}
        <div className="main-panel">
          <div className="content-wrapper">
            <ClientHeader userPref={userPref} />
            <AppRoutes />
          </div>
          {(isFullPageLayout === false && isLoggedIn() === true) ? <Footer /> : ""}
        </div>
      </div>
    </div>
  );
}

export default App;
