import React from 'react';
// import { Trans } from 'react-i18next';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container ht-100p pd-t-0-f">
        <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © <a href="https://www.edutoj.com/" target="_blank" rel="noopener noreferrer">Edutoj Technology </a>2023-{new Date().getFullYear()}</span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">The School World a initiative of <a href="https://www.edutoj.com" target="_blank" rel="noopener noreferrer"> Edutoj</a>   </span>
        </div>
      </div>{/* container */}
    </footer>
  );
}

export default Footer;