import { isArray } from "lodash";
import { GET_FEES, CLEAR_FEES, UPDATE_FEE_STATUS, UPDATE_FEE_SELECTION, ADJUST_FEE_PAID, SET_FEE_PRINT_VIEW } from "../actiontypes/ActionTypes";
import { feeStatus } from "../utility";

const intialState = {};

const feeReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_FEES:
      return mapCheckbox(action.response);
    case UPDATE_FEE_STATUS:
      return updateFeeStatus(state, action.collectionAmount);
    case UPDATE_FEE_SELECTION: {
      return mapCheckbox(state, action.isSelected, action.transactionId, action.view);
    }
    case ADJUST_FEE_PAID:
      return adjustPaidFee(state, action.transactionId, action.paidAmount);
    case SET_FEE_PRINT_VIEW: {
      return filterPrintViewData(state, action.view);
    }
    case CLEAR_FEES:
      return intialState;
    default:
      return state;
  }
};
//--------------- private functions starts--------------------

function mapCheckbox(state, isSelected = false, transactionId = "", view) {
  
  let firstTimeLoad = transactionId === "";
  let newState = { ...state };
  if (newState && isArray(newState?.fees)) {
    newState.fees.map(fee => {
      if (transactionId && transactionId === fee.transactionId) {
        fee["isSelected"] = !isSelected;
      }
      if (firstTimeLoad && fee.feeStatus === feeStatus.paid.value) {
        fee["isSelected"] = true;
        fee["excludeFromUpdate"] = true;
        fee["disable"] = true;
      }
      let totalDue = (fee.lateFee !== null ? fee.lateFee + fee.amount : fee.amount);
      fee["totalDue"] = fee.paidAmount !== null ? totalDue - fee.paidAmount : totalDue;
      fee.feeStatus = firstTimeLoad ? getFeeStatusValue(fee.feeStatus) : fee.feeStatus;
      return fee;
    });
    newState["filteredFees"] = [...newState.fees];
  }

  return filterPrintViewData(newState, view);
}

const getFeeStatusValue = (value) => {

  let status = Object.keys(feeStatus).find(fs => feeStatus[fs].value === value);
  return feeStatus[status].displayName;
}

function updateFeeStatus(state, collectionAmount) {
  let balance = collectionAmount;
  let newState = { ...state };
  newState.fees.map((fee) => {
    if (balance > 0) {
      let innerBalance = balance - fee.amount;
      balance = balance - fee.amount;
      if (innerBalance < 0) {
        fee.amount = innerBalance * -1; // substarction with negeative value
        fee.feeStatus = feeStatus.partial.displayName;
      }
      else {
        fee.feeStatus = feeStatus.paid.displayName;
        fee.amount = 0;
      }
    }
    return fee;
  });
  
  return newState;
}

function adjustPaidFee(state, transactionId, amount) {
  amount = parseFloat(amount);
  let newState = { ...state };
  if (newState && isArray(newState?.fees)) {
    newState.fees.map(fee => {
      if (transactionId && transactionId === fee.transactionId) {
        fee.paidAmount = isNaN(amount) ? "" : amount;

        let totalDue = (fee.lateFee !== null ? fee.lateFee + fee.amount : fee.amount);
        fee["totalDue"] = fee.paidAmount !== null ? totalDue - fee.paidAmount : totalDue;
        if (fee.paidAmount > 0 && fee.paidAmount < totalDue) {
          fee.feeStatus = fee["totalDue"] !== fee.paidAmount ? feeStatus.partial.displayName : fee.feeStatus;
        }
        else if (fee.paidAmount === null || fee.paidAmount <= 0) {
          fee.feeStatus = feeStatus.due.displayName;
        }
        else if (fee.paidAmount === totalDue) {
          fee.feeStatus = feeStatus.paid.displayName;
          fee["excludeFromUpdate"] = false;
        }
      }

      return fee;
    });
    newState["filteredFees"] = [...newState.fees];
  }
  return newState;
}

function filterPrintViewData(state, view) {
  let newState = { ...state };
  if (newState && isArray(newState?.fees)) {
    let fees = [...newState?.fees]
    switch (view) {
      case feeStatus.due.value: {
        newState["filteredFees"] = isArray(fees) ? fees.filter(f => f.feeStatus === feeStatus.due.displayName) : [];
        break;
      }
      case feeStatus.paid.value: {
        newState["filteredFees"] = isArray(fees) ? fees.filter(f => f.feeStatus === feeStatus.paid.displayName) : [];
        break;
      }
      default: {
        newState["filteredFees"] = [...fees];
      }
    }
  }
  return newState;
}
//--------------- private functions starts--------------------
export default feeReducer;