import * as actiontypes from '../actiontypes/ActionTypes';

const transportRouteLocationReducer = (state = [], action) => {
    switch (action.type) {
        case actiontypes.GET_TRANSPORT_ROUTE_LOCATIONS: {
            return action.response;
        }
        default:
            return state;
    }
};

export default transportRouteLocationReducer;