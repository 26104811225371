const OtpReducer = (state = "", action) => {
  switch (action.type) {
    case "ValidateOtp":
      return action.response;
    default:
      return state;
  }
};

const generateOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case "GENERATE_OTP":
      return action.response;
    default:
      return state;
  }
};

export { OtpReducer, generateOtpReducer };
