import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Spinner from "./shared/Spinner";

const Dashboard = lazy(() => import("./admin/dashboard/Dashboard"));
const Login = lazy(() => import("./public/Login"));
const GuardedRoute = lazy(() => import("./admin/common/GuardedRoute"));
const Registration = lazy(() => import("./public/registration/Registration"));
const StudentFeeConatiner = lazy(() => import("./fees/FeeContainer"));
const StudentContainer = lazy(() => import("./students/StudentContainer"));
const ResetPassword = lazy(() => import("./public/userLogin/ResetPassword"));
const SuccessPage = lazy(() => import("./public/userLogin/SuccessPage"));
const PackageSubscriptionMessage = lazy(() => import("./shared/PackageSubscriptionMessage"));
const RouteContainer = lazy(() => import("./transports/routes/RouteContainer"));
const LocationContainetr = lazy(() => import("./transports/locations/LocationContainer"));
const VendorContainer = lazy(() => import("./transports/vendors/VendorContainer"));
const AssignLocationContainer = lazy(() => import("./transports/routes/locations/AssignLocationContainer"));
const VendorLocationFeeContainer = lazy(() => import("./transports/vendors/locationfees/VendorLocationFeeContainer"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route exact path="/" element={<Login />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/resetpassword" element={<ResetPassword />} />
        <Route exact path="/admission" element={<GuardedRoute component={<Registration />} permissionName="canViewResult" />}></Route>
        {/* <Route exact path="/demo" element={<GuardedRoute component={<Demo />} permissionName="canViewResult" />}></Route> */}
        <Route exact path="/managefees" element={<GuardedRoute component={<StudentContainer />} permissionName="canViewResult" />}></Route>
        <Route exact path="/managefees/:studentId/fees" element={<GuardedRoute component={<StudentFeeConatiner />} permissionName="canViewResult" />}></Route>
        <Route exact path="/feecollection/:studentId/fees" element={<GuardedRoute component={<StudentFeeConatiner />} permissionName="canViewResult" />}></Route>
        <Route exact path="/feecollection" element={<GuardedRoute component={<StudentContainer />} permissionName="canViewResult" />}></Route>
        <Route exact path="/students" element={<GuardedRoute component={<StudentContainer />} permissionName="canViewResult" />}></Route>
        <Route exact path="/dashboard" element={<GuardedRoute component={<Dashboard />} permissionName="canViewResult" />}></Route>
        <Route exact path="/students/:studentId/fees" element={<GuardedRoute component={<StudentFeeConatiner />} permissionName="canViewResult" />}></Route>
        <Route exact path="/student-attendances" element={<GuardedRoute component={<PackageSubscriptionMessage />} permissionName="canViewResult" />}></Route>
        <Route exact path="/teacher-attendances" element={<GuardedRoute component={<PackageSubscriptionMessage />} permissionName="canViewResult" />}></Route>

        {/* Transport routes */}
        <Route exact path="/transports/students" element={<GuardedRoute component={<StudentContainer />} permissionName="canViewResult" />}></Route>
        <Route exact path="/transports/vendors" element={<GuardedRoute component={<VendorContainer />} permissionName="canViewResult" />}></Route>
        <Route exact path="/transports/vendors/:vendorId/locations" element={<GuardedRoute component={<VendorLocationFeeContainer />} permissionName="canViewResult" />}></Route>
        <Route exact path="/transports/routes" element={<GuardedRoute component={<RouteContainer />} permissionName="canViewResult" />}></Route>
        <Route exact path="/transports/routes/:routeId/locations" element={<GuardedRoute component={<AssignLocationContainer />} permissionName="canViewResult" />}></Route>
        <Route exact path="/transports/locations" element={<GuardedRoute component={<LocationContainetr />} permissionName="canViewResult" />}></Route>

        <Route
          exact
          path="/resetsuccessful"
          element={<SuccessPage />}
        ></Route>
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
