import { configureStore } from "@reduxjs/toolkit";
import apiMiddleware from "../middlewares/apiMiddleware";
import ErrorSlice from "../reducers/ErrorSlice";
import userReducer from "../reducers/UserReducer";
import { generateOtpReducer } from "../reducers/OtpReducer";
import userAuthReducer from "../reducers/UserAuthReducer";
import userPreferencesReducer from "../reducers/UserPreferencesReducer";
import studentClassReducer from "../reducers/StudentClassReducer";
import studentLanguageReducer from "../reducers/StudentLanguageReducer";
import studentGenderReducer from "../reducers/StudentGenderReducer";
import studentReducer from "../reducers/StudentReducer";
import feeReducer from "../reducers/FeeReducer";
import registrationReducer from "../reducers/RegistrationReducer";
import transportRouteReducer from "../reducers/TransportRouteReducer";
import transportLocationReducer from "../reducers/TransportLocationReducer";
import transportVendorReducer from "../reducers/TransportVendorReducer";
import transportRouteLocationReducer from "../reducers/TransportRouteLocationReducer";
import transportVendorLocationFeeReducer from "../reducers/TransportVendorLocationFeeReducer";

export default configureStore({
  reducer: {
    error: ErrorSlice,
    user: userReducer,
    userAuth: userAuthReducer,
    userPreferences: userPreferencesReducer,
    otp: generateOtpReducer,
    classList: studentClassReducer,
    languageList: studentLanguageReducer,
    genderList: studentGenderReducer,
    studentList: studentReducer,
    feeList: feeReducer,
    registrationResponse: registrationReducer,
    transportRoutes: transportRouteReducer,
    transportLocations: transportLocationReducer,
    transportVendors: transportVendorReducer,
    transportRouteLocations: transportRouteLocationReducer,
    transportVendorFeeLocations: transportVendorLocationFeeReducer
  },
  middleware: [apiMiddleware],
});
