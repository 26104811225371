const studentReducer = (state = [], action) => {
    switch (action.type) {
        case "SEARCH_STUDENTS": {
            return action.response;
        }
        case "CLEAR_SEARCH_STUDENTS":
            return [];
        default:
            return state;
    }
};

export default studentReducer;