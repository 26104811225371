import { isArray } from 'lodash';
import * as actiontypes from '../actiontypes/ActionTypes';

const transportLocationReducer = (state = [], action) => {
    switch (action.type) {
        case actiontypes.GET_TRANSPORT_LOCATION: {
            return mapCheckbox(action.response);
        }
        case actiontypes.UPDATE_TRANSPORT_LOCATION_SELECTION:
            return mapCheckbox(state, action.selected, action.id);
        case actiontypes.CLEAR_TRANSPORT_LOCATION:
            return [];
        default:
            return state;
    }
};

function mapCheckbox(state, isSelected = false, id = "") {
    let newState = [...state];
    if (newState && isArray(newState)) {
        newState.map(loc => {
            if (id && id === loc.locationId) {
                loc["isSelected"] = !isSelected;
            }
            return loc;
        });
    }
    return newState;
}

export default transportLocationReducer;