const studentLanguageReducer = (state = [], action) => {
    switch (action.type) {
      case "STUDENT_LANGUAGE": {
        return action.response;
      }
      default:
        return state;
    }
  };
  
  export default studentLanguageReducer;
  