import * as actiontypes from '../actiontypes/ActionTypes';

const registrationReducer = (state = 0, action) => {
    switch (action.type) {
        case actiontypes.STUDENT_REGISTRATION: {
            return action.response;
        }
        case actiontypes.CLEAR_REGISTRATION_RESPONSE: {
            return 0;
        }
        default:
            return state;
    }
};

export default registrationReducer;