const studentClassReducer = (state = [], action) => {
  switch (action.type) {
    case "STUDENT_CLASS": {
      return action.response;
    }
    default:
      return state;
  }
};

export default studentClassReducer;
