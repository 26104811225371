import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { setAuthorization } from "../../actions/UserActions";
import { clearAuthenticationData } from "../../config/utility";
import { useDispatch } from 'react-redux';
import { getUserPreferences } from '../../utility';

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userPref = getUserPreferences();
  const renderUserName = () => {
    return userPref.firstName != null ? userPref.firstName : "" + " " + userPref.lastName != null ? userPref.lastName : "";
  }

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }



  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row no-print">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link style={{ color: "white", fontWeight: "bold", fontSize: "1.2rem" }} className="navbar-brand brand-logo" to="/dashboard"><img src={require("../../assets/images/theschoolworld.png")} alt="logo" /></Link>
        <Link className="navbar-brand brand-logo-mini d-inline-block d-lg-none" to="/"><img src={require("../../assets/images/theschoolworld-small.png")} alt="logo" /></Link>
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="icon-menu"></span>
        </button>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center">
        <h5 className="mb-0 font-weight-medium d-none d-xl-flex">
          Welcome to The School World!
        </h5>
        <ul className="navbar-nav navbar-nav-right">
          {/* <form className="search-form d-none d-lg-block" action="!#" onSubmit={evt => evt.preventDefault()}>
            <i className="icon-magnifier"></i>
            <input type="search" className="form-control" placeholder="Search Here" title="Search here" />
          </form>
          <li className="nav-item"><a href="!#" onClick={evt => evt.preventDefault()} className="nav-link"><i className="icon-basket-loaded"></i></a></li>
          <li className="nav-item"><a href="!#" onClick={evt => evt.preventDefault()} className="nav-link"><i className="icon-chart"></i></a></li> 
          <li className="nav-item message-dropdown">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <i className="icon-speech"></i>
                <span className="count-number bg-danger">7</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="preview-list navbar-dropdown">
                <h6 className="p-3 mb-0">
                  Messages
                </h6>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("../../assets/images/faces/face4.jpg")} alt="user" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                      Mark send you a message
                    </h6>
                    <p className="text-gray mb-0">
                      1
                      Minutes ago

                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("../../assets/images/faces/face2.jpg")} alt="user" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                      Cregh send you a message
                    </h6>
                    <p className="text-gray mb-0">
                      15
                      Minutes ago

                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <img src={require("../../assets/images/faces/face3.jpg")} alt="user" className="profile-pic" />
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject ellipsis mb-1 font-weight-normal">
                      Profile picture updated
                    </h6>
                    <p className="text-gray mb-0">
                      18
                      Minutes ago

                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center cursor-pointer">
                  4 new messages
                </h6>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item dropdown language-dropdown d-none d-md-flex align-items-center">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link d-flex align-items-center">
                <div className="d-inline-flex mr-2">
                  <i className="flag-icon flag-icon-us"></i>
                </div>
                <span className="profile-text font-weight-normal">
                  English
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <i className="flag-icon flag-icon-us mr-2"></i>
                  English

                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <i className="flag-icon flag-icon-fr mr-2"></i>
                  French

                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <i className="flag-icon flag-icon-ae mr-2"></i>
                  Arabic

                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <i className="flag-icon flag-icon-ru mr-2"></i>
                  Russian

                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>*/}
          <li className="nav-item nav-profile d-none d-sm-flex">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link">
                <div className="nav-profile-img">
                  <img src={require("../../assets/images/faces/face8.jpg")} alt="user" />
                  <span className="availability-status online"></span>
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">{renderUserName()}</p>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <div class="dropdown-header text-center">
                    <img class="img-md rounded-circle" src={require("../../assets/images/faces/face8.jpg")} alt="user" />
                    <p class="mb-1 mt-3">{renderUserName()}</p>
                    <p class="font-weight-light text-muted mb-0">kleinhenry@gmail.com</p>
                  </div>
                </Dropdown.Item>
                {/* <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <p class="preview-item d-flex align-items-center m-0">
                    <i class="dropdown-item-icon icon-user text-primary mr-2"></i> My Profile <span class="badge badge-pill badge-danger ml-2">1</span>
                  </p>
                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <p class="preview-item d-flex align-items-center m-0">
                    <i class="dropdown-item-icon icon-speech text-primary mr-2"></i> Messages
                  </p>
                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <p class="preview-item d-flex align-items-center m-0">
                    <i class="dropdown-item-icon icon-energy text-primary mr-2"></i> Activity
                  </p>
                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <p class="preview-item d-flex align-items-center m-0">
                    <i class="dropdown-item-icon icon-question text-primary mr-2"></i> FAQ
                  </p>
                </Dropdown.Item> */}
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <p
                    onClick={() => {
                      clearAuthenticationData();
                      dispatch(setAuthorization("false")).then(() =>
                        navigate("/login")
                      );
                    }}
                    class="preview-item d-flex align-items-center m-0">
                    <i class="dropdown-item-icon icon-power text-primary mr-2"></i>Sign Out
                  </p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="icon-menu"></span>
        </button>
      </div>
    </nav >
  );
}

export default Navbar;
