import * as actiontypes from '../actiontypes/ActionTypes';

const transportVendorReducer = (state = [], action) => {
    switch (action.type) {
        case actiontypes.GET_TRANSPORT_VEDNOR: {
            return action.response;
        }
        default:
            return state;
    }
};

export default transportVendorReducer;