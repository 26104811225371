const ClientHeader = ({ userPref }) => {
    return <div className="print-only">
        <div className="client-info">
            <div className="client-logo"><img className="client-logo-image" src={userPref?.logoFileName?.length > 0 ? require("../../assets/images/" + userPref?.logoFileName) : ""} /></div>
            <h4 className="client-name">{userPref?.organizationName}</h4>
            <h6 className="client-address">{userPref?.organizationAddress}</h6>
        </div>
    </div>
}

export default ClientHeader;