function createUser(url, user) {
  return {
    type: "CREATE_USER",
    meta: {
      api: {
        method: "post",
        url: url,
        data: user,
      },
    },
  };
}

function userPreferences(url) {
  return {
      type: "userPreferences",
      meta: {
        api: {
            method: "Get",
            url: url,
        },
      },
    };
}

function setAuthorization(isAuth) {
  return {
      type: "userAuth",
      isAuth: isAuth
    };
}


export {createUser , userPreferences , setAuthorization};
