// import config from "./configs/developement.json";

// function getBaseUri() {
//   return config.SERVER_BASE_URL;
// }

// function getEnvData() {
//   if (process.env.NODE_ENV !== "production") {
//     // analytics.disable();
//   }
// }

function getUserPreferences() {
  return JSON.parse(localStorage.getItem("userPreferences"));
}

function getAcademicYears() {
  const userPref = getUserPreferences();
  const establishmentYear = userPref?.establishmentYear;
  const academicYearList = [];
  let academicStartYear = new Date().getFullYear();
  let academicEndYear = new Date().getFullYear();

  if (establishmentYear) {
    academicStartYear = establishmentYear
  }

  while (academicStartYear <= academicEndYear) {
    const fromToYear = academicStartYear + "-" + (academicStartYear + 1);
    academicYearList.push({ id: fromToYear, name: fromToYear });
    academicStartYear++;
  }

  return academicYearList;
}

function formatDate(date) {
  var jsDate = new Date(date);
  if (date && jsDate.getFullYear() >= 100) {

    return date ? jsDate.getDate() + " " + getMonthName((jsDate.getMonth() + 1)) + " " + jsDate.getFullYear() : "";
  }
  return "";
}

function sumOfArray(arr, n) {
  // base or terminating condition
  if (n <= 0) {
    return 0;
  }

  // Calling method recursively
  return sumOfArray(arr, n - 1) + arr[n - 1];
}

const feeStatus = {
  none: { displayName: "None", value: "None", badge: "" },
  due: { displayName: "Due", value: "Due", badge: "warning" },
  paid: { displayName: "Paid", value: "Paid", badge: "success" },
  overdue: { displayName: "Overdue", value: "Overdue", badge: "danger" },
  partial: { displayName: "Partially paid", value: "Partially_Paid", badge: "info" },
}

function getMonthName(monthValue) {
  switch (monthValue) {
    case 1: return "Jan"
    case 2: return "Feb"
    case 3: return "Mar"
    case 4: return "Apr"
    case 5: return "May"
    case 6: return "Jun"
    case 7: return "Jul"
    case 8: return "Aug"
    case 9: return "Sep"
    case 10: return "Oct"
    case 11: return "Nov"
    case 12: return "Dec"
    default: return "Invalid Month"
  }
}

export {
  formatDate,
  feeStatus,
  sumOfArray,
  getUserPreferences,
  getAcademicYears
};
