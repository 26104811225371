const userPreferencesReducer = (state = [], action) => {
  switch (action.type) {
    case "userPreferences": {
      localStorage.setItem("userPreferences", JSON.stringify(action.response));
      return action.response;
    }
    default:
      return state;
  }
};

export default userPreferencesReducer;
