import { useEffect, useState } from "react";
import "./Toast.css";

const Toast = ({ visible, onCloseClick, message, type }) => {
  const [hidden, setHiddine] = useState(false);

  useEffect(() => {
    if (type != "error") {
      // setTimeout(() => { setHiddine(true) }, 2000);
    }

  }, [])
  return (
    visible === true && (
      <section className="toast-main">
        <section className="">
          <section className={type === "error" ? "errorContainer" : "success-container"}>
            <section> {message}</section>
            <section
              className="errorCross"
              onClick={() => {
                onCloseClick(false);
              }}
            >
              X
            </section>
          </section>
        </section>
      </section>
    )
  );
};

export default Toast;
