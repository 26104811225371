import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Toast from "../toast/Toast";
import "./Error.css";

const Error = () => {
  const [localErrorState, setLocalErrorState] = useState([]);
  const errors = useSelector((store) => store.error);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setLocalErrorState(errors);
    if (errors && Array.isArray(errors) && errors.length > 0) {
      setIsVisible(true);
    }
  }, [errors]);

  const onCloseClickHandler = (value) => {
    setIsVisible(value);
  };

  return (
    <>
      <section className="error-main">
        {localErrorState &&
          Array.isArray(localErrorState) &&
          localErrorState.length > 0 &&
          localErrorState.map((item) => {
            return (
              <Toast
                visible={isVisible}
                message={item}
                type="error"
                onCloseClick={onCloseClickHandler}
              />
            );
          })}
      </section>
    </>
  );
};

export default Error;
