import * as actiontypes from '../actiontypes/ActionTypes';

const transportVendorLocationFeeReducer = (state = [], action) => {
    switch (action.type) {
        case actiontypes.GET_TRANSPORT_VEDNOR_LOCATION_FEES: {
            return action.response;
        }
        default:
            return state;
    }
};

export default transportVendorLocationFeeReducer;