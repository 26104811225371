import * as actiontypes from '../actiontypes/ActionTypes';

const transportRouteReducer = (state = [], action) => {
    switch (action.type) {
        case actiontypes.GET_TRANSPORT_ROUTE: {
            return action.response;
        }
        case actiontypes.CLEAR_TRANSPORT_ROUTE:
            return [];
        default:
            return state;
    }
};

export default transportRouteReducer;